<!-- 预警通知 -->

<template>
  <div class="notice">
    <div class="globle_border" v-loading="loading">
      <!-- 顶部搜索 -->
      <div class="search">
        <el-select v-model="typeValue" placeholder="通知类型" @change="handleSelect1" clearable>
          <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
          </el-option>
        </el-select>
        <el-date-picker v-model="dataValue" type="daterange" popper-class="sift-data" range-separator="至"
          start-placeholder="开始日期" end-placeholder="结束日期" @change="handleSelect2" clearable value-format="yyyy-MM-dd">
        </el-date-picker>
      </div>

      <!-- 表格 -->
      <div class="globle_table">
        <el-table :data="tableData" style="width: 100%" max-height="540">
          <el-table-column label="序号" show-overflow-tooltip>
            <template slot-scope="scope">
              {{ scope.$index + 1 + (currentPage - 1) * pageSize }}
            </template>
          </el-table-column>
          <el-table-column show-overflow-tooltip prop="warnType" label="通知类型">
            <template slot-scope="scope">
              <span>{{ scope.row.warnType == '1' ? '缺货预警' : '收货预警' }}</span>
            </template>
          </el-table-column>
          <el-table-column show-overflow-tooltip prop="warnDate" label="时间"></el-table-column>
          <el-table-column label="操作" header-align="center" align="center">
            <template slot-scope="scope">
              <el-button size="small" class="btn" type="primary" plain @click="handleCheck(scope.row)">查看</el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>

    </div>

    <!-- 分页 -->
    <el-pagination background @size-change="handleSizeChange" @current-change="handleCurrentChange"
      :current-page.sync="currentPage" :page-size="pageSize" :page-sizes="pageSizeArr"
      layout="prev, pager, next, jumper, sizes, total" :total="totalItemsCount">
    </el-pagination>

  </div>
</template>

<script>
export default {
  data() {
    return {
      loading: false,
      options: [{
        value: '1',
        label: '缺货预警'
      }, {
        value: '2',
        label: '收货预警'
      },],
      typeValue: '', // 通知类型
      dataValue: '', // 日
      startTime: '', // 开始时间
      endTime: '', // 结束时间
      // 表格数据
      tableData: [],
      // 分页
      currentPage: 1, // 当前页码
      pageSize: 10, // 每页显示的行数
      pageSizeArr: [10, 20, 50], // 每页显示的行数(可选)
      totalItemsCount: 0, // 总记录数（需要从后端获取）
    }
  },
  created() {
    this.getWarningNoticeList();
  },
  methods: {
    // 获取列表
    getWarningNoticeList() {
      this.loading = true;
      let params = {
        warnType: this.typeValue || null, //类型
        page: this.currentPage,
        pageSize: this.pageSize,
      };

      // 时间区间
      if (this.dataValue && this.dataValue.length === 2) {
        params.startDate = this.dataValue[0];
        params.endDate = this.dataValue[1];
      } else {
        params.startDate = null;
        params.endDate = null;
      }

      this.$axios.get(this.$api.getWarningNoticeList, { params })
        .then((res) => {
          if (res.data.code == 100) {
            this.tableData = res.data.result.list;
            this.totalItemsCount = res.data.result.totalCount;
          }
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },

    // 选择
    handleSelect1(value) {
      this.clearData();
      this.typeValue = value
      this.getWarningNoticeList();
    },
    handleSelect2(value) {
      this.clearData();
      this.dataValue = value
      this.getWarningNoticeList();
    },
    // 查看
    handleCheck(row) {
      const warnDate = row.warnDate;
      const startDate = new Date(warnDate);
      const tomorrow = new Date(startDate);
      tomorrow.setDate(startDate.getDate() + 1);
      const endDate = tomorrow.toISOString().split('T')[0];
      // 使用 endDate
      this.$router.push({
        path: 'detail/noticeView',
        query: {
          type: row.warnType,
          startDate: row.warnDate,
          endDate: endDate
        }
      });
    },
    // 清空
    clearData() {
      this.tableData = [];
      this.currentPage = 1;
    },
    // 搜索
    onSearch() {
      this.clearData();
      this.getWarningNoticeList();
    },
    // 切换每页显示的条数
    handleSizeChange(e) {
      this.clearData();
      this.pageSize = e;
      this.getWarningNoticeList();
    },
    // 换页
    handleCurrentChange(e) {
      this.tableData = [];
      this.currentPage = e;
      this.getWarningNoticeList();
    },
  }

}



</script>

<style scoped lang="scss">
.notice {


  // 顶部搜索
  .search {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    height: 44px;
    margin-bottom: 20px;

    .el-input {
      width: 180px;
      margin-left: 20px;
    }

    /deep/ .el-date-editor {
      background-color: #f2f2f2;
      border: none;
      width: 280px;
      border-radius: 10px;
      margin-left: 10px;
    }

    /deep/.el-range-input {
      background-color: #f2f2f2;

    }
  }
}
</style>
